<template>
  <div id="orderForm" class="fixedMode">
    <div class="mainScroll">
      <!-- <div class="topAddrWrap"> -->
        <!-- <template v-if="!userAddrLoading"
          ><div class="addrWrap newAdd" v-if="!userAddr" @click="editAddr(0)">
            添加收货地址
          </div>
          <div class="addrWrap aAddr" v-else @click="editAddr(1)">
            <p class="name">
              {{ userAddr.name
              }}<span class="mobile">{{ userAddr.mobile }}</span>
            </p>
            <p class="addr">
              {{
                userAddr.provinceName +
                userAddr.cityName +
                userAddr.districtName +
                " " +
                userAddr.detailAddress
              }}
            </p>
          </div>
        </template> -->
      <!-- </div> -->
      <div class="partBg">
        <aOrderGoods
          :isShowNum="false"
          :buyCount="buyCount"
          :imgUrl="imgUrl"
          :goodsInfo="spuInfo"
          :skuAttr="skuAttr"
        ></aOrderGoods>
        <div class="orderDetail">
          <div class="orderDetailBar">
            <span class="itemName">购买数量</span>
            <div class="itemCon">
              <numStepper
                :defaultValue="buyCount"
                :max="!skuInfo || !skuInfo.limitCount ? -1 : skuInfo.limitCount"
                @change="buyCountUpdate"
              ></numStepper>
            </div>
          </div>
          <!-- <div class="orderDetailBar">
            <span class="itemName">配送方式</span>
            <div class="itemCon">快递 包邮</div>
          </div> -->
          <div class="orderDetailBar">
            <span class="itemName">发货时间</span>
            <div class="itemCon">现在下单，48小时内发货</div>
          </div>
           <div class="orderDetailBar" @click="openDiscount">
            <span class="itemName">选择优惠券</span>
            <div class="itemCon">{{tipText}}</div>
          </div>
          <!-- <div class="orderDetailBar">
            <span class="itemName">下单优惠</span>
            <div class="itemCon">江苏移动尊享会员专享8折优惠</div>
          </div> -->
        </div>
      </div>
    </div>
    <!-- 优惠券选择 -->
    <transition name="selectMask-show">
      <div class="selectDiscount" v-show="disCountSelect">
        <div class="pullWrap">
          <!-- <div class="pullWrapbg"> -->
          <!-- </div> -->
          <div class="pullTitle">选择优惠券
             <span class="closeBtn" @click="disCountSelect = false"></span>
          </div>
          <div class="discountModule">
            <div class="discountItem" v-for="(discount,index) in disCountList" :key="index" ref="discountCode" v-show="!discount.available">
            <div class="mc" v-if="selectCtrl(discount)"></div>
          <div class="itemTop" @click="selDiscount(discount,index)">
            <div class="itemTopLeft">
                <p class="itemNum">{{discount.couponPrice}}</p>
                <p class="itemLimit">{{'满'+discount.useMinPrice+'可使用'}}</p>
            </div>
            <div class="itemTopCenter">
                <div class="name"> <span class="type">{{discount.ctype=='0'?'通用券':'商品券'}}</span>{{discount.cname}}</div>
                <div class="limitData">有效期至:{{(discount.endTime).split(' ')[0]}}</div>
            </div>
            <div class="itemTopRight">
              <div ref='selBox' class="unsel"></div>
            </div>
          </div>
          <div class="itemBottom">
            <div class="seeDetail" @click="openDesc(index)">
              <p>不可叠加使用</p>
              <p ref='arrowDown'>查看详情<span class="arrowBottom"></span></p>
            </div>
            <div class="visiDetail" ref="detail" @click="closeDesc(index)">
              <div class="discountItemBottom"  ref="discountBottom">
                <p class="limitCategory">{{'限品类:'+discount.limitCategory}}</p>
                <p class="limitPlantform">{{'限平台:'+discount.limitPlantform}}</p>
              </div>
              <p class="detailUp">查看详情<span class="arrowUp"></span></p>
            </div>
          </div>
        </div>
          </div>
        </div>
      </div>
    </transition>


    <div class="bottomBtn">
      <p class="total">
        <span class="count">共计{{ buyCount || 0 }}件商品，</span>
        <span class="prize">
          合计
          <span class="num">{{ totalPrice }}</span>
        </span>
      </p>
      <button
        type="button"
        class="buybtn"
        :disabled="submiting"
        @click="submitOrder"
      >
        立即购买
      </button>
    </div>
  </div>
</template>

<script>
// import { mapState, mapActions } from "vuex";
import numStepper from "@/components/goods/numStepper";
import aOrderGoods from "@/components/goods/aOrderGoods";
export default {
  name: "orderForm",
  data() {
    return {
      spuId: this.$route.query.spuId,
      sceneNo: this.$route.query.sceneNo,
      productId:this.$route.query.productId,
      spuInfo: null,
      skuInfo: null,
      imgUrl: "",
      buyCount: 1,
      skuAttr: null,
      userAddr: null,
      userAddrLoading: true,
      submiting: false,
      disCountSelect:false,
      disCountList:[],
      couponId:'',
      tipText:'请选择优惠券',
      discountNum:0,
      // element:this.$refs.selBox
    };
  },
  watch:{
    // element:function(newVal,oldVal){
    //   console.log(newVal)
    // }
  },
  computed: {
    // ...mapState({
    //   //  用户当前地址
    //   userAddr: (state) => state.userAddr
    // }),
    //  总价格
    totalPrice() {
      return (
        (this.spuInfo &&
          this.spuInfo.currentPrice &&
          this.global.decimalPlace(
            this.spuInfo.currentPrice * this.buyCount-this.discountNum,
            2
          )) ||
        0
      );
    }
  },
  components: {
    numStepper,
    aOrderGoods
  },
  created() {
    // 从选择商品页面跳转而来会有缓存 【防止此页面被分享
    let baseInfo = sessionStorage.getItem(`spuBuy${this.productId}`);
    console.log(baseInfo+'==========='+`spuBuy${this.productId}`)
    if (!baseInfo) {
      //  如果没有缓存 跳回详情页
      this.$router.replace({
        path: "/h/scm/virtual/goodsDetail",
        query: {
          sceneNo: this.sceneNo,
          productId: this.productId
        }
      });
      return;
    }
    baseInfo = JSON.parse(baseInfo);
    this.spuInfo = baseInfo.spuInfo;
    this.skuInfo = baseInfo.skuInfo;
    this.imgUrl = baseInfo.imgUrl || "";
    this.buyCount = baseInfo.buyCount || 1;
    this.skuAttr = baseInfo.skuAttr || {};
    this.getDefaultAddr(); 
    this.getDiscountList();
  },
  mounted() {
      console.log(this.$refs.discountCode)
  },
  updated(){
      console.log(this.$refs.discountCode)
  },
  methods: {
    selDiscount(discount,index){
      const {id,couponTitle,couponPrice} = discount;
      var selBox = this.$refs.selBox;
      console.log(selBox)
      for(let i=0;i<selBox.length;i++){
        selBox[i].className = 'unsel'
      }
      if( this.couponId == id){
        selBox[index].className='unsel'
        this.couponId = '';
        this.tipText='请选择优惠券';
         this.discountNum=0;
      }else{
         selBox[index].className='sel';
         this.couponId = id;
         this.tipText=couponTitle;
         this.discountNum=couponPrice;
      }
   
    },
    selectCtrl(discount){
      const {id,couponTitle,couponPrice,useMinPrice} = discount;
      if(this.spuInfo.currentPrice-useMinPrice>0||this.spuInfo.currentPrice-useMinPrice==0){
        return false
      }else{
        return true
      }
    },
     openDesc(index){
          var detailItem = this.$refs.detail;
          var arrowDown = this.$refs.arrowDown;
          arrowDown[index].className = 'visiBity'
          detailItem[index].className = 'detail'
        },
        closeDesc(index){
          var detailItem = this.$refs.detail;
          var arrowDown = this.$refs.arrowDown;
          arrowDown[index].className = ''
          detailItem[index].className = 'visiDetail'
        },
    // 获取优惠券列表
    getDiscountList(){
      this.request.post('/api/scm/store/coupon/user/list',{fisProductId:this.$route.query.productId,orderBy:'coupon_price desc, create_time desc',createTime:'2021-08-17 09:38:33.216'}).then(res =>{
          if(res.list.length!=0){    
            res.list.forEach((item,index)=>{
              this.disCountList.push(item)
            })
            this.$nextTick(()=>{  
                try{
                  this.disCountList.forEach((item,index)=>{
                    if(this.spuInfo.currentPrice-item.useMinPrice>0||this.spuInfo.currentPrice-item.useMinPrice==0){
                      this.selDiscount(item,index);
                      throw new Error('找到了');
                    }else{
                       this.tipText = '暂无可用优惠券'
                    }
                  })
                }catch (e) {
                 
                }; 
            });

          }else{
            this.tipText = '暂无可用优惠券'
          }

         
      })
    },
    //  购买数量更新
    buyCountUpdate(val) {
      this.buyCount = val;
      // 更新缓存中的购买数量
      let baseInfo = sessionStorage.getItem(`spuBuy${this.spuId}`);
      baseInfo = JSON.parse(baseInfo);
      baseInfo.buyCount = val;
      sessionStorage.setItem(`spuBuy${this.spuId}`, JSON.stringify(baseInfo));
    },
    //  编辑/添加新地址
    editAddr(type) {
      //  选择地址
      this.$router.push({
        path: "/h/scm/member/myAddr",
        query: {
          spuId: this.spuId,
          sceneNo: this.sceneNo
          // curAddrId: (this.userAddr && this.userAddr.id) || null
        }
      });
    },
    //  获取默认地址
    async getDefaultAddr() {
      let selectAddr = sessionStorage.getItem(`${this.spuId}Addr`); //  订单选择地址存在缓存中
      if (!!selectAddr) {
        this.userAddr = JSON.parse(selectAddr);
        this.userAddrLoading = false;
        return;
      }
      //  没有上次选择结果 找vuex中的默认地址
      let _defaultAddr = this.$store.state.defaultAddr;
      if (!!_defaultAddr) {
        this.userAddr = _defaultAddr;
        this.userAddrLoading = false;
        return;
      }
      try {
        let res = await this.request.get("/api/scm/user/address/default");
        if (res && res.id) {
          this.userAddr = res;
          this.$store.commit("UPDATEDEFAULTADDR", res);
        }
        this.userAddrLoading = false;
      } catch (e) {
        this.userAddrLoading = false;
      }
    },
    // ...mapActions(["getUserAddr"])
    // 提交订单
    submitOrder() {
        this.request.post('/api/scm/order/virtual/virtual/create',{bizId:this.$route.query.bizId,
            sceneNo:this.$route.query.sceneNo,
            couponId:this.couponId,
            placeVirtualDTOList:[{
            productId: this.productId,
            placeNum: this.buyCount
        }]}).then(res=>{
            if(res){
            sessionStorage.setItem(`${this.$route.query.bizId}Order`, JSON.stringify(res));
            // sessionStorage.setItem(`${orderId}Order`, JSON.stringify(this.goods))
            this.$router.push({
            path: "/h/scm/virtual/virtualPay",
            query: {
              sceneNo: this.sceneNo,
              productId: this.productId,
              bizId:this.$route.query.bizId
            }
          });
            }
        })
      
    },
    pay0(orderId) {
      let payBizId = orderId + String(new Date().getTime()).substr(-4, 4);
      this.request
        .postFrom(
          `/api/scm/pay/order?bizId=${orderId}&payType=1&payBizId=${payBizId}`,
          {}
        )
        .then((res) => {
          if (res) {
            this.$toast({
              message: "购买成功",
              position: "bottom"
            });
            this.$router.replace({
              path: "/h/scm/order/orderDetail",
              query: {
                skuId: this.skuInfo.skuId,
                bizId: orderId
              }
            });
          }
          this.submiting = false;
        });
    },
    //选择优惠券
    selectDiscount(index,discount){
      const {id,couponTitle,couponPrice} = discount;
      var discountCard = this.$refs.discountCode;
      console.log(this.spuInfo.currentPrice+'------------'+couponPrice)
      console.log(discountCard)
      if(this.spuInfo.currentPrice-couponPrice>0){
        for(let i =0; i<discountCard.length;i++){
          discountCard[i].className = 'discountItem';
        }
        discountCard[index].className = 'discountSelItem';
        this.couponId = id;
        this.tipText = '您已选择'+couponTitle;
        this.discountNum = couponPrice;
      }else{
        this.$toast({
          message: "该卡券不满足使用条件",
          position: "bottom"
        });
      }
     
    //   this.totalPrice();
    },
    openDiscount(){
      if(this.disCountList.length=='0'){return}
      this.disCountSelect = true;
    }
  },
  beforeDestroy() {
    //  销毁可能存在的缓存
    sessionStorage.removeItem(`${this.spuId}Addr`);
  }
};
</script>

<style lang="scss" scoped>
.unsel{
  width: 38px;
  height: 38px;
  background: url('~@/assets/userCenter/unsel.png') no-repeat;
  background-size: 100% 100%;
}
.sel{
  width: 38px;
  height: 38px;
  background: url('~@/assets/userCenter/sel.png') no-repeat;
  background-size: 100% 100%;
}
.fixedMode {
  background: #efefef;
}
 .visiBity{
      visibility: hidden;
    }
    .arrowBottom{
      display: inline-block;
      width: 18px;
      height: 11px;
      background: url('~@/assets/userCenter/arrowDown.png') no-repeat;
      background-size: 100% 100%;
    }
    .arrowUp{
      display: inline-block;
      width: 18px;
      height: 11px;
      background: url('~@/assets/userCenter/arrowUp.png') no-repeat;
      background-size: 100% 100%;
    }
.topAddrWrap {
  min-height: 185px;
  margin: 20px;
  background: #fff;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  &::after {
    content: "";
    display: block;
    bottom: 0;
    left: 0;
    right: 0;
    height: 10px;
    position: absolute;
    background: linear-gradient(
        135deg,
        transparent 15%,
        #f76a84 15%,
        #f76a84 45%,
        transparent 45%,
        transparent 60%,
        #6ab3f7 60%,
        #6ab3f7 90%,
        transparent 90%
      )
      center 100% / 112px 10px;
  }
  .addrWrap {
    box-sizing: border-box;
    &.newAdd {
      height: 175px;
      padding-top: 118px;
      text-align: center;
      color: #888;
      font-size: 28px;
      background: url(~@/assets/icons/addAddr.png) center 35px / 52px auto
        no-repeat;
      line-height: 1;
    }
    &.aAddr {
      min-height: 175px;
      padding: 15px 20px 25px 125px;
      margin-right: 30px;
      background: url(~@/assets/icons/addr.png) 50px center / 35px auto
          no-repeat,
        url(~@/assets/icons/right-arrow.png) right center / auto 25px no-repeat;
      .name {
        font-size: 30px;
        white-space: nowrap;
        line-height: 50px;
        margin-bottom: 10px;
        .mobile {
          font-size: 24px;
          color: #999;
          display: inline-block;
          margin-left: 25px;
        }
      }
      .addr {
        line-height: 30px;
        font-size: 24px;
        color: #333;
        padding-right: 100px;
      }
    }
  }
}

.partBg {
  background: #fff;
  border-radius: 20px;
  padding: 30px 30px 20px;
  margin: 0 20px 30px;
}

.goodsWrap {
  margin-bottom: 35px;
}
.orderDetail .orderDetailBar {
  min-height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .itemName {
    display: inline-block;
    color: #666;
    margin-right: 20px;
    font-size: 26px;
  }
  .itemCon {
    font-size: 26px;
  }
}
.bottomBtn {
  height: 160px;
  display: flex;
  background: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  .total {
    font-size: 30px;
    flex: 1;
    .count {
      color: #999;
      font-size: 26px;
    }
    .prize {
      display: inline-block;
      .num {
        color: #ea3d39;
        font-size: 36px;
        &::before {
          content: "¥ ";
          font-size: 30px;
        }
      }
    }
  }
  .buybtn {
    width: 275px;
    height: 96px;
    display: block;
    border-radius: 69px;
    background: linear-gradient(to right, #f62300, #fa5421);
    color: #fff;
    font-size: 36px;
    margin-left: 20px;
  }
}
.selectDiscount{
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    z-index: 12;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 750px;
    height: 100vh;
    .pullWrap{
      width: 750px;
      position: absolute;
      bottom: 0;
      left: 0;
      overflow-y: auto;
      max-height: 100%;
      background: #f3f3f3;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      overflow: hidden;
      .pullTitle{
        width: 750px;
        height: 100px;
        font-size: 32px;
        line-height: 100px;
        text-align: center;
        color: #000;
        font-weight: 500;
        position: relative;
        .closeBtn {
          width: 55px;
          height: 55px;
          display: block;
          background: url(~@/assets/icons/closeBtn.png) left bottom / 35px auto
                    no-repeat;
          position: absolute;
          right: 10px;
          top: 5px;
          z-index: 1;
        }
      }
      .discountModule{
        height: 645px;
        overflow-y: auto;
        .discountItem,.unDiscountItem{
          width: 710px;
        //   height: 160px;
          margin:20px auto;
          background: #f7deaf;
          box-sizing: border-box;
          border-radius: 0.1rem;
          position: relative;
          .mc{
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 0.1rem;
            background: rgba(255, 255, 255, 0.6);
            z-index: 2;
          }
          .itemTop{
            height: 130px;
            width: 634px;
            padding:0 38px;
            display: flex;
            display: -webkit-flex;
            border-bottom:1px dashed #dcb268;
            align-items: center;
            .itemTopLeft{
              width: 200px;
              height: 100%;
              display: flex;
              display: -webkit-flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              .itemNum{
                font-size: 48px;
                color: #8b5d0e;
                font-weight: bold;
                position: relative;
              }
              .itemNum::before{
                content: '￥';
                position: absolute;
                top: 5px;
                left: -20px;
                z-index: 1;
                font-size: 22px;

              }
              .itemLimit{
                font-size: 20px;
                color: #c3a167;
              }
            }
            .itemTopCenter{
              width: 370px;
              display: flex;
              display: -webkit-flex;
              flex-direction: column;
              justify-content: center;
              .name{
                font-size: 20px;
                color: #777065;
                .type{
                  font-size: 30px;
                  color: #000000;
                  font-weight: bold;
                }
              
              }
              .limitData{
                padding-top: 15px;
                font-size: 22px;
                color: #e7ca98;
              }
            }
            .itemTopRight{
              // font-size: 24px;
              // width: 130px;
              // height: 48px;
              // color: #c69865;
              // background: #eaca96;
              // border-radius: 20px;
              // text-align: center;
              // line-height: 48px;
            }
          }
          .itemBottom{
            min-height: 50px;
            width: 635px;
            margin: 0 auto;
            .seeDetail{
              height: 50px;
              display: flex;
              display: -webkit-flex;
              justify-content: space-between;
              align-items: center;
              p{
                font-size: 20px;
                color: #8b5d0e;
              }

            }
            .visiDetail{
               position: relative;
               display:none;
              .discountItemBottom{
                width: 635px;
                margin: 0 auto;
                padding:20px 0;
                font-size: 20px;
                color: #b08b4c;
            }
            .detailUp{  
                position: absolute;
                right: 0;
                bottom: 20px;
                font-size: 20px;
                color: #8b5d0e;
            }
            }
            .detail{
               position: relative;
               display: block;
              .discountItemBottom{
                width: 635px;
                margin: 0 auto;
                padding:20px 0;
                font-size: 20px;
                color: #b08b4c;
            }
            .detailUp{  
                position: absolute;
                right: 0;
                bottom: 20px;
                font-size: 20px;
                color: #8b5d0e;
            }
            }
          
          }
        }
      }
    }

}
</style>